import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/envioronment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  user:any;
  token:string;
  modulo:String;
  permisos = []
  constructor(private http: HttpClient, private router:Router) { }


  loginByPassword(credentials:any):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}login/`,credentials)
  }
  getToken(): string{
    return this.token;
  }
  validateToken():Observable<any>{
    //this.validate_token()
    return this.http.post<any>(`${environment.apiUrl}validartoken/`,{token : this.token})
  }
  enviar_contrasenia(email):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}restaurar_contrasenia/`,email)
  }
  validate_token(): Promise<boolean> {
    let headers = new HttpHeaders({'Authorization':this.getToken()});
    let requestOptions = {headers : headers};
    return new Promise(resolve =>{
      this.http.post<any>(`${environment.apiUrl}validartoken/`,{token:this.token}, requestOptions).subscribe(
            (response) => { 
                if(response['valido']){
                    this.user = response['data'];
                    response['data'].permisos.forEach(el => {
                      this.permisos.push(el.permisos_modulos_nombre_permiso)
                    });
                    
                    // console.log("************", permisos_parseados)      
                    
                    // this.setToken(response['token']);
                    //this.router.navigate(['/home'])
                    resolve(true);
                } else {
                
                    this.doLogout();
                    resolve(false);
                }
            },
            (err) => { 
            
              resolve(false); 
        });
    });
}

  setToken(token:string) {
    localStorage.setItem('token',token);
    this.token=token;
  }

  _initializeToken(){
    let token = localStorage.getItem('token');
    if(token!=undefined){
        this.token = token;
    } else{
        this.token = null;
        this.doLogout();
    }
  }
  doLogout(){
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    
  }
  
}
